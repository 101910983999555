<template>
  <div class="rule-page">
    <a href="javascript:void(0)" @click="goBack()"
      ><img src="../../icons/icon-arrow-left_big.svg"
    /></a>
    <div v-if="currentRule" v-html="currentRule.content"></div>
		<orfus-loader v-else class="loader" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import OrfusLoader from "./OrfusLoader.vue";

export default {
  components: { OrfusLoader },
  computed: {
    ...mapState("test", ["currentRule"])
	},
	created() {
		// console.log(this.$route.params.id)
		if (this.$route.params.id) {
			this.getRule(this.$route.params.id);
		}
	},
  methods: {
		...mapActions({ getRule: "test/getRule" }),
    goBack() {
      this.$router.go(-1);
    }
  }

}
</script>

<style lang="scss">
.rule-page {
  z-index: 100000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px;
  background: #fff;
  overflow-y: scroll;
}
 .rule-page .section {
	 padding: 100px 25px 50px;
	 overflow: hidden;
	 width: 12000px;
}
 .rule-page .section .section-name {
	 font-size: 50px;
	 line-height: 1;
	 padding: 0 25px 50px;
}
 .rule-page .section .lifehacks {
	 margin-left: 50px;
}
 .rule-page .section .lifehacks .lifehack-wrapper {
	 float: left;
	 margin: 25px;
	 width: 320px;
	 font-size: 18px;
	 line-height: 24px;
}
 .rule-page .section .lifehacks .lifehack-wrapper .lifehack {
	 padding: 16px;
	 height: 196px;
	 box-sizing: border-box;
	 outline: 1px solid rgba(0, 0, 0, .2);
	 font-size: 18px;
	 line-height: 24px;
	 position: relative;
}
 .rule-page .section .lifehacks .lifehack-wrapper .lifehack .meta-info {
	 position: absolute;
	 font-size: 12px;
	 color: rgba(0, 0, 0, .6);
	 top: -34px;
	 left: 0;
	 line-height: 15px;
}
 .rule-page .words-list {
	 font-size: 13px;
	 line-height: 1;
}
 .rule-page .words-list th {
	 color: rgba(0, 0, 0, .5);
	 font-weight: normal;
	 padding-right: 10px;
}
 .rule-page .words-list td {
	 padding-right: 10px;
}
 .rule-page .section .rule {
	 width: 320px;
	 outline: 1px solid rgba(0, 0, 0, .2);
	 box-sizing: border-box;
	 margin: 25px;
	 padding: 16px;
	 float: left;
	 font-size: 18px;
	 line-height: 24px;
}
 .rule-page h1 {
	 font-size: 32px;
	 line-height: 40px;
	 margin: 0 0 12px;
}
 .rule-page h1:not(:first-child) {
	 margin-top: 60px;
}
 .rule-page h2 {
	 font-size: 18px;
	 line-height: 24px;
	 margin: 24px 0 8px;
}
 .rule-page ul {
	 margin: 0 0 8px;
	 padding: 0 0 0 20px;
}
 .rule-page ul li:not(:last-child) {
	 margin-bottom: 8px;
}
 .rule-page ol {
	 margin: 0 0 8px;
	 padding: 0;
	 font-style: italic;
}
 .rule-page ol li {
	 display: block;
}
 .rule-page .cols {
	 overflow: hidden;
}
 .rule-page .cols .col {
	 float: left;
}
 .rule-page .cols .col:not(:last-child) {
	 margin-right: 24px;
}
 .rule-page p {
	 margin-top: 0;
	 margin-bottom: 8px;
}
 .rule-page b {
	 position: relative;
	 font-weight: normal;
}
 .rule-page b:after {
	 content: "";
	 position: absolute;
	 display: block;
	 background: #eea941;
	 z-index: -1;
	 height: 2px;
	 bottom: 0;
	 left: -1px;
	 right: 0;
}
 .rule-page em {
	 font-weight: normal;
	 font-style: none;
	 white-space: nowrap;
}
 .rule-page i b:after {
	 transform: skew(-13deg);
}
 .rule-page table {
	 border-spacing: 0;
	 border-collapse: collapse;
	 margin-bottom: 8px;
}
 .rule-page table td {
	 padding: 0;
	 padding-right: 24px;
}
 .rule-page th {
	 padding: 0;
	 font-weight: normal;
	 text-align-last: left;
	 color: rgba(76, 60, 0, .5);
	 text-align: left;
}
 .rule-page .small-divider {
	 height: 8px;
}
 .rule-page .normal-divider {
	 height: 16px;
}
 .rule-page .article-view {
	 padding: 10px;
	 width: 20000px;
}
 .rule-page .article {
	 box-sizing: border-box;
	 margin: 10px;
	 float: left;
	 font-size: 18px;
	 line-height: 24px;
	 margin-left: 250px;
	 display: flex;
	 position: relative;
}
 .rule-page .article .article-name {
	 font-size: 12px;
	 color: rgba(0, 0, 0, .6);
	 position: absolute;
	 left: 0;
	 top: -14px;
}
 .rule-page .article .rule {
	 outline: 1px solid rgba(0, 0, 0, .2);
	 padding: 16px;
	 width: 288px;
	 margin-top: 10px;
	 margin-right: 30px;
}
 .rule-page .article .lifehacks {
	 width: 320px;
	 font-size: 18px;
	 line-height: 24px;
	 margin-top: 10px;
}
 .rule-page .article .lifehack {
	 padding: 4px 16px;
	 max-height: 152px;
	 outline: 1px solid #ddd;
	 margin-top: 1px;
	 position: relative;
}
 .rule-page .article .lifehack p {
	 margin: 0;
}
 .rule-page .article .lifehack p + p {
	 margin-top: 8px;
}
 .rule-page .article .lifehack .meta-info {
	 position: absolute;
	 font-size: 12px;
	 color: rgba(0, 0, 0, .6);
	 top: 0px;
	 left: 330px;
	 line-height: 15px;
}
</style>
