<template>
  <div>
    <canvas
      ref="roundChart"
      v-bind:width="canvasSize"
      v-bind:height="canvasSize"
      v-bind:style="{
        width: canvasRealSize + 'px',
        height: canvasRealSize + 'px'
      }"
    >
    </canvas>
  </div>
</template>

<script>
export default {
  props: {
    radius: Number,
    progress: Number,
    lineWidth: Number,
    backColor: String,
    mainColor: String,
    startRadius: {
      type: Number,
      default: 0.5
    },
    endRadius: {
      type: Number,
      default: 2.5
    }
  },
  data: () => ({
    ctx: null
  }),
  computed: {
    canvasSize: function () {
      return (this.radius + this.lineWidth) * 2;
    },
    canvasRealSize: function () {
      return this.radius + this.lineWidth;
    }
  },
  watch: {
    progress: function (val) {
      this.drawRoundChart(val);
    }
  },
  methods: {
    drawRoundChart() {
      this.ctx.lineWidth = this.lineWidth * 2;
      this.ctx.strokeStyle = this.backColor;
      this.ctx.lineCap = "round";
      this.ctx.beginPath();
      this.ctx.arc(
        this.lineWidth + this.radius,
        this.lineWidth + this.radius,
        this.radius,
        this.startRadius * Math.PI,
        this.endRadius * Math.PI
      );
      this.ctx.stroke();

      if (this.progress != 0) {
        this.ctx.lineWidth = this.lineWidth * 2;
        this.ctx.strokeStyle = this.mainColor;
        this.ctx.lineCap = "round";
        this.ctx.beginPath();
        this.ctx.arc(
          this.lineWidth + this.radius,
          this.lineWidth + this.radius,
          this.radius,
          this.startRadius * Math.PI,
          (this.startRadius +
            (this.endRadius - this.startRadius) * this.progress) *
            Math.PI
        );
        this.ctx.stroke();
      }
    }
  },
  mounted() {
    this.ctx = this.$refs.roundChart.getContext("2d");
    this.drawRoundChart(this.chart);
  }
};
</script>

<style></style>
