<template>
  <div>
    <div class="rules-list">
      <router-link
        v-for="(rule, index) in rules"
        :key="index"
        class="rules-list__item rules-list__item_active"
        :to="`rules/${rule.name}`"
        v-show="rule.is_free"
      >
        <div class="rules-list__item__title" v-html="rule.title"></div>
        <svg class="rules-list__item__icon icon-arrow" stroke="currentColor" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 6.5L15 12L9.5 17.5" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </router-link>
      <router-link
        v-for="(rule, index) in rules"
        :key="index+100"
        class="rules-list__item rules-list__item_active"
        :to="`rules/${rule.name}`"
        v-show="hasSubscription"
      >
        <div class="rules-list__item__title" v-html="rule.title"></div>
        <svg class="rules-list__item__icon icon-arrow" stroke="currentColor" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.5 6.5L15 12L9.5 17.5" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </router-link>
      <router-link
        v-for="(rule, index) in rules"
        :key="index+200"
        class="rules-list__item rules-list__item_disabled"
        to="sub"
        v-show="!hasSubscription"
      >
        <div class="rules-list__item__title" v-html="rule.title"></div>
        <svg class="rules-list__item__icon icon-lock" stroke="currentColor" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="4" y="11.5" width="16" height="8.5" rx="2" stroke-width="1.44"/><path d="M8 9C8 6.79086 9.79086 5 12 5V5C14.2091 5 16 6.79086 16 9V11.5H8V9Z" stroke-width="1.44"/><line x1="12" y1="15" x2="12" y2="16.5" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  async created() {
    this.getRules();
  },
  computed: {
    ...mapState("test", ["rules"]),
    ...mapState("user", ["hasSubscription"])
  },
  methods: {
    ...mapActions({
      getRules: "test/getRules"
    }),
    loadRule(ruleName) {
      this.getRule(ruleName);
    }
  }
};
</script>

<style lang="scss" scoped>
.rules-list__item_active .icon-lock {
  display: none;
}
.rules-list__item_disabled .icon-arrow {
  display: none;
}

.defaul-p {
  margin: 12px 16px;
  font-size: 18px;
  line-height: 22px;
}

.alert {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, .07);
  border-radius: 4px;
  padding: 12px 16px 12px 48px;
  margin: 16px;
  position: relative;
  color: #3C3C3C;
}

.alert-icon {
  position: absolute;
  top: 12px;
  left: 16px;
}

.alert p {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
}

.rules-list {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;

  .rules-list__item {
    color: #000;
    text-decoration: none;
    display: block;
    font-size: 15px;
    line-height: 18px;
    padding: 16px;
    padding-right: 40px;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-align: left;

    &.rules-list__item_disabled {
      color: #999;
    }

    .rules-list__item__title {
      font-size: 18px;
      line-height: 22px;
      padding-right: 20px;
    }

    .rules-list__item__icon {
      position: absolute;
      top: 13px;
      right: 16px;
    }
  }
}
</style>
