import axios from "axios";
const DOMAIN = "/api";

export const NETWORK_ERROR = "NETWORK_ERROR";

const api = axios.create({
  baseURL: DOMAIN
  /* other custom settings */
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem("auth");
  if (token) {
    config.headers.common["x-auth-token"] = token;
  }
  return config;
});

const handleError = e => {
  if (!e.response) {
    return { error: true, type: NETWORK_ERROR };
  } else {
    return { error: true, type: e.response.status };
  }
};

export default {
  get: async (url, parameters) => {
    try {
      const { data } = await api.get(url, parameters);
      return data;
    } catch (e) {
      console.error(`api GET error ${url}`, e);
      return handleError(e);
    }
  },
  post: async (url, parameters) => {
    try {
      const { data } = await api.post(url, parameters);
      return data;
    } catch (e) {
      console.error(`api POST error ${url}`, e);
      return handleError(e);
    }
  },
  delete: async (url, parameters) => {
    try {
      const { data } = await api.delete(url, { data: parameters });
      return data;
    } catch (e) {
      console.error(`api DELETE error ${url}`, e);
      return handleError(e);
    }
  }
};
