<template>
  <div class="sub-wrapper" :class="{'sub-centered': !pageText.showList}">
      <a class="close-btn" @click="back()"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 5.5L12 12M18.5 18.5L12 12M12 12L18.5 5.5M12 12L5.5 18.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></a>
      <div class="profile-content">
        <h1>{{pageText.title}}</h1>
        <p>{{pageText.text}}</p>
        <p v-show="pageText.text2 != ''">{{pageText.text2}}</p>
        <ul class="features-list" v-show="pageText.showList">
          <li>
            <svg class="feature-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.2144 2.32041L14.3194 7.75159C14.6358 8.56792 15.4006 9.1236 16.2748 9.17224L22.0906 9.49589C22.1586 9.49967 22.1966 9.5204 22.2218 9.54088C22.2518 9.56521 22.2802 9.6039 22.2966 9.65444C22.313 9.70498 22.3128 9.75295 22.3029 9.79024C22.2945 9.82162 22.2759 9.86078 22.2232 9.90376L17.7083 13.5841C17.0297 14.1372 16.7375 15.0363 16.9614 15.8827L18.4508 21.5139C18.4682 21.5797 18.4602 21.6223 18.4485 21.6526C18.4347 21.6886 18.4066 21.7276 18.3636 21.7588C18.3206 21.79 18.275 21.8047 18.2364 21.8067C18.204 21.8085 18.161 21.8029 18.1038 21.766L13.2085 18.6094C12.4727 18.1349 11.5273 18.1349 10.7915 18.6094L5.89617 21.766C5.83901 21.8029 5.796 21.8085 5.76358 21.8067C5.72504 21.8047 5.67936 21.79 5.63637 21.7588C5.59337 21.7276 5.56534 21.6886 5.55145 21.6526C5.53976 21.6223 5.53182 21.5797 5.54921 21.5139L7.0386 15.8827C7.26246 15.0363 6.97033 14.1372 6.29172 13.5841L1.77684 9.90376C1.72412 9.86078 1.70548 9.82162 1.69713 9.79024C1.68721 9.75295 1.68701 9.70498 1.70343 9.65444C1.71985 9.6039 1.74821 9.56521 1.77815 9.54088C1.80336 9.52039 1.84145 9.49967 1.90937 9.49589L7.72521 9.17224C8.59936 9.1236 9.36417 8.56793 9.68057 7.75159L11.7856 2.32041C11.8101 2.25698 11.8416 2.22716 11.8689 2.20952C11.9013 2.18856 11.9469 2.17354 12 2.17354C12.0531 2.17354 12.0987 2.18856 12.1311 2.20952C12.1584 2.22716 12.1898 2.25698 12.2144 2.32041Z" stroke="currentColor" stroke-width="2"/></svg>
            <h2>+3000 слов</h2>
            <p>Этих слов хватит, чтобы выучить всю орфографию</p>
          </li>
          <li>
            <svg class="feature-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 22V14.9032M4 14.9032V3.29032C4 3.29032 4.61538 2.00011 7.69231 2C10.7692 1.99989 13.2308 4.58065 16.3077 4.58065C19.3846 4.58065 20 3.29032 20 3.29032V14.9032C20 14.9032 19.3846 16.1935 16.3077 16.1935C13.2308 16.1935 10.7692 13.6129 7.69231 13.6129C4.61538 13.6129 4 14.9032 4 14.9032Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
            <h2>Закрепляйте слова</h2>
            <p>Работа над ошибками самая важная часть обучение</p>
          </li>
          <li>
            <svg class="feature-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 5.5939V21M12 5.5939C12 5.5939 14.5 3.26918 17 3.02621C19.5 2.78324 22 4.31005 22 4.31005V19.7162C22 19.7162 19.5 18.1894 17 18.4323C14.5 18.6752 12 21 12 21M12 5.5939C12 5.5939 9.5 3.2691 7 3.02621C4.5 2.78332 2 4.31005 2 4.31005V19.7162C2 19.7162 4.5 18.1894 7 18.4323C9.5 18.6752 12 21 12 21" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
            <h2>Читайте правила</h2>
            <p>Все правила по орфографии расписаны понятно</p>
          </li>
        </ul>
      </div>
      <span onclick="ym(70427569,'reachGoal','sub')">
      <div v-show="pageText.showList">
        <a class="btn btn_primary btn_sub" @click="buy()">{{pageText.btnText}}</a>
      </div>
      </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageText: {},
      title: "Учитесь эффективней"
    }
  },
  mounted() {
    this.isNeedUpdate = !window.PurchasesBuy;
    this.pageText = {
      title: "Продолжить премиум",
      text: "",
      text2: "",
      btnText: "1 месяц за 2.99$",
      showList: true
    }
    this.pageText = {
      title: "Учитесь эффективней",
      text: "Начните 3-дневный пробный период, а потом платите всего 2.99$ в месяц",
      text2: "",
      btnText: "Начать пробный период",
      showList: true
    }
    if (this.isNeedUpdate) {
      ym(70427569,'reachGoal','prevVersion');
      this.pageText = {
        title: "Обновите приложение!",
        text: "Чтобы иметь возможность закреплять слова и читать все правила обновите приложение.",
        text2: "Найдите приложение «Орфография» в «Play Маркет» -> «Мои приложения» и нажмите кнопку «Обновить».",
        btnText: "Обновить",
        showList: false
      }
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    buy() {
      if (window.PurchasesBuy) {
        var message = JSON.stringify({
          android: "monthly01",
        });
        window.PurchasesBuy.postMessage(message);
      } else {
        // YP: only for debug on desktop
        var confirmed = window.confirm("Доступно только на мобиле!");
        if (confirmed) {
          window.app.purchased();
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .sub-centered {
    display: flex;
    align-items: center;
  }

  .sub-centered h1 {
    margin-top: 0;
  }

  .features-list {
    margin-top: 32px;
  }

  .sub-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(204.04deg, #0070D8 25.28%, #28BFFF 85.21%);
  }

  .profile-content {
    width: 290px;
    margin: 0 auto;
  }

  .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    color: rgba(255, 255, 255, .6);
  }

  .feature-icon {
    color: #FFF739;
    position: absolute;
    top: 14px;
    left: 16px;
  }

  .profile-content {
  }

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 56px;
    margin-bottom: 16px;
  }

  h1 + p,
  h1 + p + p {
    font-family: PT Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
    opacity: 0.7;
    margin: 16px 0;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    display: block;
    padding: 8px 16px 8px 52px;
    background: rgba(0, 63, 136, 0.16);
    border-radius: 4px;
    position: relative;
    margin-bottom: 16px;
  }

  h2 {
    margin: 0;
    margin-top: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    color: #FFFFFF;
  }

  li p {
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    opacity: 0.7;
    margin-top: 8px;
    margin-bottom: 5px;
  }

  .btn_sub {
    position: fixed;
    left: 24px;
    right: 24px;
    bottom: 24px;
    background: #FFF739;
    color: #525766;
    font-weight: bold;
  }
</style>
