import api from "../../services/api";

const SET_TOKEN = "SET_TOKEN";
const SET_USER = "SET_USER";
const SET_LAST_ANSWER = "SET_LAST_ANSWER";
const SET_POINTS = "SET_POINTS";
const SET_LAST_ANSWER_LOADING = "SET_LAST_ANSWER_LOADING";
const SET_POINTS_LOADING = "SET_POINTS_LOADING";
const SET_WORD_PROGRESS_SCORE = "SET_WORD_PROGRESS_SCORE";
const SET_HAS_SUBSCRIPTION = "SET_HAS_SUBSCRIPTION";

export default {
  namespaced: true,
  state: () => ({
    token: null,
    user: null,
    lastAnswer: null,
    lastAnswerLoading: false,
    userPoints: [],
    userPointsLoading: false,
    hasSubscription: false,
    wordProgressScore: 0
  }),
  getters: {
    userProgress: state => {
      let result = 0;
      if (state.lastAnswer) {
        if (state.lastAnswer.rightNum == 0 && state.lastAnswer.wrongNum == 0) {
          result = 0;
        } else {
          let realLiteracy0 = 0.6;
          let realLiteracy100 = 0.96;

          result =
            (state.lastAnswer.rightNum /
              (state.lastAnswer.wrongNum + state.lastAnswer.rightNum) -
              realLiteracy0) /
            (realLiteracy100 - realLiteracy0);
        }
        if (result > 1) {
          result = 1;
        } else if (result < 0) {
          result = 0;
        }
      }
      return result;
    }
  },
  actions: {
    checkToken: ({ commit }) => {
      const token = localStorage.getItem("auth");
      if (token) {
        commit(SET_TOKEN, token);
      }
    },
    getUser: async ({ commit }) => {
      const user = await api.get("/user");
      if (user) {
        commit(SET_USER, user);
      }
    },
    getLastAnswer: async ({ commit }) => {
      commit(SET_LAST_ANSWER_LOADING, true);
      const lastAnswer = await api.get("/user/answers/last");
      if (lastAnswer) {
        commit(SET_LAST_ANSWER, lastAnswer);
      }
      commit(SET_LAST_ANSWER_LOADING, false);
    },
    getPoints: async ({ commit }) => {
      commit(SET_POINTS_LOADING, true);
      const points = await api.get("/points");
      commit(SET_POINTS, points);
      commit(SET_POINTS_LOADING, false);
    },
    savePoint: async () => {
      return await api.post("/points");
    },
    login: async ({ commit }, options) => {
      const response = await api.post("/user/enter", options);
      if (response && response.token) {
        localStorage.setItem("auth", response.token);
        commit(SET_TOKEN, response.token);
        commit(SET_USER, { email: response.email });
        return response;
      } else {
        return false;
      }
    },
    changeWordProgressScore: async ({ commit }, wordProgressScore) => {
      commit(SET_WORD_PROGRESS_SCORE, wordProgressScore);
    },
    checkSubscription: async ({ commit }) => {
      const response = await api.get("/subscriptions");
      if (response) {
        commit(SET_HAS_SUBSCRIPTION, response.paid);
      }
    }
  },
  mutations: {
    [SET_TOKEN](state, token) {
      state.token = token;
    },
    [SET_USER](state, user) {
      state.user = user;
    },
    [SET_LAST_ANSWER](state, lastAnswer) {
      // Vue.set(state, "lastAnswer", lastAnswer);
      state.lastAnswer = lastAnswer;
    },
    [SET_LAST_ANSWER_LOADING](state, status) {
      state.lastAnswerLoading = status;
    },
    [SET_POINTS](state, points) {
      state.userPoints = points;
    },
    [SET_POINTS_LOADING](state, status) {
      state.userPointsLoading = status;
    },
    [SET_WORD_PROGRESS_SCORE](state, status) {
      state.wordProgressScore = status;
    },
    [SET_HAS_SUBSCRIPTION](state, hasSubscription) {
      state.hasSubscription = hasSubscription;
    }
  }
};
