<template>
  <div>
    <div class="profile-content">
      <div v-show="!hasSubscription">
        <p class="defaul-p">Оформите премиум подписку, чтобы учиться эффективнее</p>
        <router-link class="btn btn_primary" to="/sub">Оформить премиум-подписку</router-link>
      </div>
      <div v-show="hasSubscription">
        <p class="defaul-p">Премиум-подписка активна.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import RoundChart from "./RoundChart.vue";
import OrfusLoader from "./OrfusLoader.vue";

export default {
  computed: {
    ...mapState("user", ["hasSubscription"])
  }
}
</script>

<style lang="scss" scoped>
.change-sub-btn {
  color: #3C3C3C;
}

.btn {
  margin: 16px 16px 0;
}

.defaul-p {
  margin: 16px 16px 12px;
  font-size: 18px;
  line-height: 22px;
}

.alert {
  background: #fafafa;
  border: 1px solid rgba(0, 0, 0, .07);
  border-radius: 4px;
  padding: 12px 16px 12px 48px;
  margin: 16px;
  position: relative;
  color: #3C3C3C;
}

.alert-icon {
  position: absolute;
  top: 12px;
  left: 16px;
}

.alert p {
  margin: 0;
  font-size: 18px;
  line-height: 22px;
}
</style>
