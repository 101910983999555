<template>
  <div v-if="tests && tests.length" class="app-wrapper" :class="appState">
    <div class="finish-screen" :class="{'finish-active': wordScore == initialTestLength && wordScore != 0}">
      <div class="finish-screen-content">
        <h1>Вы закрепили<br>все слова!</h1>
        <router-link
          class="btn btn_secondary btn_repetition btn_finish"
          to="/"
        >Хорошо</router-link>
      </div>
    </div>
    <router-link class="back-btn" :to="{ name: 'home' }"><img src="../../icons/icon-arrow-left_big.svg" /></router-link>
    <div class="loading-overlay" v-if="!isAppLoaded"></div>
    <div v-if="showLoading" class="loading"></div>
    <orfus-repetition-progress
      :word-progress-score="wordScore"
      :words-till-progress="initialTestLength"></orfus-repetition-progress>
    <orfus-two-option-tests
      :tests="tests"
      :active-test-index="activeTestIndex"
      :answer="answer"
      :btn-state="btnState"></orfus-two-option-tests>
    <router-view></router-view>
    <a
      href="javascript:void(0)"
      class="btn btn_next btn_wrong"
      :class="btnState"
      v-on:click="next()"
      >Дальше</a
    >
  </div>
  <div v-else><orfus-loader class="loader" /></div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import OrfusLoader from "./OrfusLoader.vue";
import OrfusRepetitionProgress from "./OrfusRepetitionProgress.vue";
import OrfusTwoOptionTests from "./OrfusTwoOptionTests.vue";
let rightSound1 = require("../../audio/right1.mp3");
let rightSound2 = require("../../audio/right2.mp3");
let progressSound = require("../../audio/progressbar.mp3");

export default {
  components: { OrfusLoader, OrfusRepetitionProgress, OrfusTwoOptionTests },
  data() {
    return {
      userScore: 0,
      wordScore: 0,
      showBuyScreen: false,
      btnState: "btn_disabled",
      appState: "default-state",
      activeTestIndex: 0,
      rightSound1: new Audio(rightSound1),
      rightSound2: new Audio(rightSound2),
      progressSound: new Audio(progressSound),
      authToken: null,
      wordsInTrial: 100,
      wordsTotal: 100,
      showLoading: true,
      showNoInternetMessage: false,
      isAppLoaded: false,
      wordsTillProgress: 6,
      scoreTillStreak: 10,
      scoreTillWordProgress: 10,
      wordProgressScore: 0,
      initialTestLength: 0
      // currentRule: "",
      // ruleShow: false
    };
  },
  mounted() {
    // document.getElementsByTagName("body")[0].style.height = "100vh";
    // document.getElementsByTagName("body")[0].style.overflow = "hidden";
  },
  computed: {
    ...mapState("user", ["token", "user", "userPoints"]),
    ...mapState("test", {
      tests: "repetitionWords",
      currentRule: "currentRule",
      noInternet: "noInternet",
      loading: "loading"
    }),
    canvasSize() {
      return (this.radius + this.lineWidth / 2) * 2;
    },
    streakcolor() {
      return this.userScore >= this.scoreTillStreak ? "#FF8A00" : "#E6E6EF";
    }
  },
  async created() {
    if (this.token) {
      await this.getRepetitionWords(4);
      this.showLoading = true;
      this.isAppLoaded = false;
      await this.getPoints();
      this.updateUserScore();
      this.initialTestLength = this.tests.length;
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    ...mapActions({
      getPoints: "user/getPoints",
      savePoint: "user/savePoint",
      getNewWord: "test/getNewWord",
      sendAnswer: "test/sendAnswer",
      getRule: "test/getRule",
      getRepetitionWords: "test/getRepetitionWords",
      deleteRepetitionWord: "test/deleteRepetitionWord"
    }),
    playRightSound() {
      if (Math.random() < 0.5) {
        this.rightSound1.currentTime = 0;
        this.rightSound1.play();
      } else {
        this.rightSound2.currentTime = 0;
        this.rightSound2.play();
      }
    },
    playProgressSound() {
      this.progressSound.currentTime = 0;
      this.progressSound.play();
    },
    answer(test, optionIndex, testIndex) {
      this.appState = "";
      if (this.activeTestIndex != testIndex || this.btnState == "btn_active") {
        return null;
      }
      this.getNewWord();
      let classes = [];
      if (test.options[optionIndex].isRight) {
        // console.log({"word": test.word});
        this.deleteRepetitionWord({"word": test.word});
        // console.log(test);
        classes[0] = "option_right";
        classes[1] = "option_was-wrong";
        this.activeTestIndex += 1;
        this.wordScore++;
        this.playRightSound();
      } else {
        this.appState = "answer-was-wrong";
        classes[0] = "option_wrong";
        classes[1] = "option_was-right";
        this.btnState = "btn_active";
        this.tests.push(JSON.parse(JSON.stringify(this.tests[this.activeTestIndex])));
        console.log(this.tests);

        const ruleName = this.tests[this.activeTestIndex].lifehack.rule;
        if (ruleName) {
          this.getRule(ruleName);
        }
      }
      if (optionIndex) {
        let temp = classes[0];
        classes[0] = classes[1];
        classes[1] = temp;
      }
      test.options[0].answerState = classes[0];
      test.options[1].answerState = classes[1];

      let testsLeft = this.tests.length - this.activeTestIndex;
      if (testsLeft == 0) {
        this.showNoInternetMessage = true;
        this.showLoading = true;
      }
      this.sendAnswer({
        word: test.word,
        isRight: test.options[optionIndex].isRight
      });
      if (this.userScore >= this.wordsInTrial) {
        this.showBuyScreen = true;
        this.appState = "buy-state";
      }
    },
    next() {
      this.btnState = "btn_disabled";
      this.activeTestIndex += 1;
      this.appState = "default-state";
    },
    updateUserScore() {
      // let comboDays = response;
      let result = 0;
      let today = new Date().toISOString().slice(0, 10);
      for (let i = 0; i < this.userPoints.length; i++) {
        if (this.userPoints[i].date == today) {
          result = this.userPoints[i].count;
        }
      }
      this.userScore = result;
    },
  }
};
</script>

<style scoped>
.btn_finish {
  border-color: #fff;
}

.finish-screen {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #41B860;
  align-items: center;
  z-index: -1;
  opacity: 0;
  transition: .4s;
  display: flex;
}

.finish-screen.finish-active {
  z-index: 999999;
  opacity: 1;
}

.finish-screen-content {
  width: 290px;
  margin: 0 auto;
}

.finish-screen h1 {
  font-size: 36px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  margin: 0;
  margin-bottom: 32px;
}

.answer-was-right .progressbar .progress {
  top: 0;
  right: 0;
  left: auto;
  transform: translateX(100px);
  transition: .6s width, .6s transform;
}

.answer-was-wrong .progressbar .progress {
  background: #FF595F;
  transition: 1.6s width;
}

/* MESSAGES SCORE */

/* .user-score {
  color: #999999;
  font-size: 14px;
  line-height: 24px;
  z-index: 100;
  position: fixed;
  left: 24px;
  top: 34px;
  right: 52px;
}

.user-score i {
  font-style: normal;;
}

.big-word-count {
  z-index: 300;
  border-radius: 4px;
  font-weight: bold;
  color: #6A6A6A;
  border-top-left-radius: 0;
  font-size: 28px;
  line-height: 24px;
  position: relative;
  left: 3px;
  top: -3px;
} */

body, html {
  font-size: 20px;
  height: 100%;
  font-family: "PT Sans", sans;
}

body {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#app {
	width: 100%;
	height: 100%;
}

.app-wrapper {
  height: 100%;
  transition: .5s;
  perspective: 50em;
  overflow: hidden;
  background: #fff;
}

.back-btn {
  position: fixed;
  left: 21px;
  top: 21px;
  z-index: 99999;
}
.back-btn img {
  display: block;
}

.btn {
  display: block;
  font-size: 20px;
  border-radius: 5px;
  padding: 15px 0;
  text-align: center;
  text-decoration: none;
  color: #fff;
  position: relative;
  line-height: 26px;
}

.btn:active {
  color: #fff;
}

.btn_wrong {
  background: #FF6363;
}

.btn_next {
  position: fixed;
  bottom: 16px;
  right: 16px;
  left: 16px;
  z-index: 160;
}

.btn_disabled {
  display: none;
}
</style>
