import Vue from "vue/dist/vue.js";
import Vuex from "vuex";
import user from "./modules/user";
import test from "./modules/test";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    test
  }
});
