<template>
  <div class="loader-block" :class="state">
    <div class="loader-content">
      <div class="spinner"></div>
      <div class="text-block">Пытаемся подключиться<br>к интернету</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'orfusLoader',
  props: {
    state: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.loader_disabled {
  opacity: 0;
}

.loader_active {
  opacity: 1;
  z-index: 1;
}

.loader-block {
  position: fixed;
  left: 0;
  right: 0;
  top: 70px;
  bottom: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 70px;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #F5F7F7;
  border-top: 4px solid #4E88FB;
  border-radius: 50%;
  box-sizing: border-box;
  margin: 0 auto;
  -webkit-animation: 4s linear 0s infinite spin;
          animation: 4s linear 0s infinite spin;
}

@-webkit-keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(3600deg); }  
}
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(3600deg); } 
}

.text-block {
  color: #666666;
  font-size: 15px;
  line-height: 18px;
  margin-top: 12px;
  text-align: center;
  position: relative;
  top: 50px;
  transition: top 1s 3s;
}

.loader-content {
  overflow: hidden;
}

.loader_disabled .loader_active {
  opacity: 0;
}

.loader_active .text-block {
  top: 0px;
}
</style>