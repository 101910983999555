<template>
  <div class="progressbar-container">
    <div class="progressbar-wrapper">
      <div class="progressbar">
        <div
          :style="{ width: (wordProgressScore * 100) / wordsTillProgress + '%' }"
          class="progress-streak"
        ></div>
      </div>
    </div>
    <div class="text-progress">Закреплено {{ wordProgressScore }} из {{ wordsTillProgress }}</div>
  </div>
</template>

<script>
export default {
  props: {
    wordScore: Number,
    wordsTillProgress: Number,
    wordProgressScore: Number
  }
};
</script>

<style scoped>
.text-progress {
  position: absolute;
  top: 42px;
  left: 24px;
  right: 24px;
  line-height: 18px;
  font-size: 15px;
  text-align: center;
  color: #666666;
  z-index: 99;
}

.progressbar-wrapper-score {
  position: fixed;
  left: 24px;
  right: 24px;
  top: 40px;
  z-index: 10000;
}

.progressbar {
  height: 8px;
  background: #F5F5F6;
  border-radius: 4px;
  overflow: hidden;
  flex-grow: 1;
  position: relative;
  margin-left: 28px;
}

.progressbar .progress {
  position: absolute;
  top: 0;
  left: 0;
  background: #38D360;
  height: 8px;
  transition: .6s width;
  z-index: 300;
  border-radius: 4px;
  transform: translateX(0px);
}

.progressbar .progress-streak {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--link);
  height: 8px;
  transition: .6s width;
  z-index: 300;
  border-radius: 4px;
  transform: translateX(0px);
}

.answer-was-right .progressbar .progress {
  top: 0;
  right: 0;
  left: auto;
  transform: translateX(100px);
  transition: .6s width, .6s transform;
}

.word-done .progressbar .progress-streak {
  top: 0;
  right: 0;
  left: auto;
  transform: translateX(100px);
  transition: .6s width, .6s transform;
}

.answer-was-wrong .progressbar .progress {
  background: #FF595F;
  transition: 1.6s width;
}

.progressbar-wrapper {
  position: fixed;
  top: 28px;
  left: 24px;
  right: 24px;
  z-index: 300;
  display: flex;
}
</style>
