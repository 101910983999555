<template>
  <div>
    <p>Вход / регистрация</p>
    <input v-model="email" type="email" id="email" placeholder="Эл. почта" />
    <input
      v-model="password"
      type="password"
      id="password"
      placeholder="Пароль"
    />
    <button @click="login" class="login-btn" id="login-btn">
      Войти в приложение
    </button>
    <p id="error">{{ errorElement }}</p>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import api from "../services/api";

export default {
  data() {
    return {
      email: "",
      password: "",
      errorElement: ""
    };
  },
  methods: {
    ...mapActions({
      loginApi: "user/login"
    }),
    redirectToHome() {
      this.$router.push({ name: "home" });
    },
    saveToken(token) {
      localStorage.setItem("auth", token);
    },
    async loginOrRegister() {
      const body = {
        email: this.email,
        password: this.password
      };
      try {
        const response = await this.loginApi(body);
        if (response) {
          this.saveToken(response.token);
          this.redirectToHome();
        }
      } catch (e) {
        console.log("error ", e);
        this.errorElement = "Неверный email или пароль!";
      }
    },
    login() {
      this.errorElement = "";
      this.loginOrRegister(this.email, this.password);
    }
  }
};
</script>

<style></style>
