<template>
  <div>
    <div class="tests-list">
      <div
        v-for="(test, index) in tests"
        class="two-options-test"
        :key="index"
        :class="getTestState(index)"
      >
        <div
          v-for="(option, option_index) in test.options"
          :key="option_index"
          class="option"
          :class="option.answerState"
          @click="answer(test, option_index, index)"
        >
          {{ test.wordStart }}<i class="option__highlight">{{ option.text }}</i
          >{{ test.wordEnd }}
        </div>
      </div>
    </div>
    <div
      class="lifehack-wrapper"
      :class="btnState"
      v-if="activeTest.lifehack.content"
    >
      <div v-html="activeTest.lifehack.content"></div>
      <span onclick="ym(70427569,'reachGoal','readRule')">
        <router-link
          :to="{ name: 'rule' }"
          v-if="activeTest.lifehack.name != 'запомнить' && hasSubscription"
          >Читать правило</router-link>
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    tests: Array,
    activeTestIndex: Number,
    btnState: String,
    answer: Function
  },
  computed: {
    ...mapState("user", ["hasSubscription"]),
    activeTest() {
      return this.tests[this.activeTestIndex];
    }
  },
  methods: {
    getTestState(index) {
      // console.log("this.activeTestIndex: " + this.activeTestIndex);
      if (this.activeTestIndex == index) {
        return "active-options";
      } else if (this.activeTestIndex + 1 < index) {
        return "none";
      } else if (this.activeTestIndex < index) {
        return "two-options-test__in-the-queue";
      } else if (this.activeTestIndex - 1 > index) {
        return "none";
      } else {
        return "two-options-test__done";
      }
    }
  }
}
</script>

<style lang="scss" scoped>


.tests-list {
  position: fixed;
  right: 24px;
  left: 24px;
  top: 50%;
  margin-top: -68px;
}
.tests-list:before,
.tests-list:after {
  content: "";
  display: block;
  position: absolute;
  left: -16px;
  right: -16px;
  height: 210px;
  z-index: 99;
}
.tests-list:before {
  top: -210px;
  background: linear-gradient(180deg, #fff 75%, rgba(255, 255, 255, 0) 100%);
}
.tests-list:after {
  top: 140px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 25%);
}





.two-options-test {
  position: absolute;
  left: 0;
  right: 0;
  transition: .6s transform;
  transform: translateY(0);
  /* opacity: 1; */
}

.two-options-test__active {}

.two-options-test__in-the-queue {
  transform: translateY(-200px);
  /* opacity: 0; */
}

.two-options-test__done {
  transform: translateY(200px);
  /* opacity: 0; */
}
.two-options-test__done .option {

}

.two-options-test .option:first-child {
  margin-bottom: 24px;
}


.btn_disabled {
  display: none;
}

.option {
  display: block;
  padding: 20px 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  color: #000;
  position: relative;
  line-height: 20px;
  font-size: 20px;
  border: 1px solid #E6E6EF;
  border-radius: 5px;
}

.option_right {
  background: #40C962;
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
}
.option_right .option__highlight:after {
  border-bottom: 2px solid #fff;
}

.option_was-wrong .option__highlight:after {
  border-bottom: 2px solid #aaa;
}

.option_wrong {
  background: #FF6363;
  color: #fff;
  border-color: rgba(0, 0, 0, 0);
}
.option_wrong .option__highlight:after {
  border-bottom: 2px solid #fff;
}

.option_was-right .option__highlight:after {
  border-bottom: 2px solid #40C962;
}

.option__highlight {
  position: relative;
  font-style: normal;
}
.option__highlight:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  bottom: -1px;
  border-bottom: 2px solid #EEA941;
}

.none {
  display: none;
}


/* LIFEHACK */

.lifehack-wrapper .btn_disabled {
  display: none;
}

.lifehack-wrapper {
  position: fixed;
  left: 24px;
  right: 24px;
  top: 50%;
  margin-top: 92px;
	font-size: 18px;
	line-height: 24px;
}

.lifehack-wrapper p {
	margin: 0 0 8px;
}

.lifehack-wrapper i {
  padding: 0 4px;
}

.lifehack-wrapper b {
	position: relative;
	font-weight: normal;
}

.lifehack-wrapper b:after {
	content: "";
	position: absolute;
	display: block;
	background: #EEA941;
	z-index: -1;
	height: 2px;
	bottom: 0;
	left: -1px;
	right: 0;
}

.lifehack-wrapper a {
  color: #4E88FB;
  text-decoration: none;
}
</style>
