<template>
  <div>
    <router-view class="content"></router-view>
    <orfusMenu v-if="showMenu" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Menu from "./Menu.vue";

const _tokenAwaiter = (tokenProvider, callback) => {
  const token = tokenProvider();
  if (token) {
    callback();
  } else {
    if (window.Mobile) {
      // HACK YP: waiting token from WebView
      setTimeout(() => _tokenAwaiter(tokenProvider, callback), 100);
    } else {
      callback();
    }
  }
};

export default {
  components: { orfusMenu: Menu },
  async created() {
    _tokenAwaiter(() => {
      this.checkToken();
      return this.token;
    }, async () => {
      const isLoginPage = this.$router.currentRoute.path === "/login";
      if (this.token) {
        this.checkSubscription();
        await this.getUser();
        if (isLoginPage) {
          this.$router.push("/");
        }
      } else if (!isLoginPage) {
        this.$router.push("/login");
      }
    })
  },
  computed: {
    ...mapState("user", ["user", "token"]),
    showMenu() {
      return !this.$route.meta.hideMenu;
    }
  },
  methods: {
    ...mapActions({
      checkToken: "user/checkToken",
      getUser: "user/getUser",
    }),
    checkSubscription() {
      if (window.PurchasesCheck) {
        window.PurchasesCheck.postMessage("");
      } else {
        // YP: only for debug on desktop
        window.app.purchased();
      }
    }
  }
};
</script>

<style lang="scss">
  :root {
    --link: #4E88FB;
    --link-light: #70A0FF;
    --success: #41B860;
    --success-light: #38D360;
    --danger: #EB5358;
    --warning: #F26C3E;
    --danger-light: #FF595F;
    --warning-light: #FF7B4E;
    --black: #000000;
    --gray-7: #666666;
    --gray-6: #999999;
    --gray-5: #CCCCCC;
    --gray-3: #EBEBEB;
    --gray-2: #EFF1F1;
    --gray-1: #F5F7F7;
    --white: #FFFFFF;

    --subscription-back: linear-gradient(204.04deg, #0070D8 25.28%, #28BFFF 85.21%);
    --subscription-accent: #FFF739;
    --subscription-text-gray: #525766;
    --subscription-back-overlay: rgba(0, 63, 136, 0.16);
    --subscription-text-white: rgba(255, 255, 255, 0.7);
  }

  @import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

  body {
      font-family: "PT Sans";
  }

  body, html {
   -webkit-touch-callout: none;
    -webkit-user-select: none;
     -khtml-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }

  input,
  textarea,
  button,
  select,
  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  .content {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 52px;
    left: 0;
  }

  /* Common styles */

  .btn {
      font-size: 18px;
      line-height: 24px;
      padding: 16px 0;
      border-radius: 5px;
      text-decoration: none;
      color: var(--white);
      display: block;
      text-align: center;

      &.btn_primary {
          background: var(--link);
      }

      &.btn_secondary {
        border: 1px solid var(--gray-5);
        color: var(--gray-6);
      }
  }
</style>
