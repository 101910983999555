import Vue from "vue/dist/vue.js";
import VueRouter from "vue-router";
import Vuex from "vuex";
import App from "./components/App.vue";
import router from "./routes";
import store from "./store";

Vue.use(Vuex);
Vue.use(VueRouter);
// console.log("vue store ", store);

// YP: DO NOT CHANGE, used from WebView
window.app = {
  setToken: token => {
    localStorage.setItem("auth", token);
  },
  purchased: () => {
    // close sub page if needed
    if (router.currentRoute.name === "sub") {
      router.go(-1);
    }
    // switch to paid user, load from server
    store.dispatch("user/checkSubscription");
  },
  purchases_error: message => {
    console.log(`purchases error: ${message}`);
  }
};

new Vue({
  components: { App },
  el: "#app_root",
  router,
  store
});
