<template>
  <div class="progressbar-container">
    <div class="progresses-block">
      <div class="progressbar-wrapper">
        <div class="progressbar">
          <div
            :style="{ width: (wordProgressScore * 100) / scoreTillStreak + '%' }"
            class="progress-streak"
          ></div>
        </div>
      </div>
      <div class="progressbar-wrapper-score">
        <div class="progressbar">
          <div
            :style="{ width: (wordScore * 100) / wordsTillProgress + '%' }"
            class="progress"
          ></div>
        </div>
      </div>
    </div>
    <div class="word-counter">
      <span v-show="userScore > 0" class="some-words">+{{userScore}}</span>
      <span v-show="userScore == 0" class="zero-words">0</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userScore: Number,
    scoreTillStreak: Number,
    wordScore: Number,
    wordsTillProgress: Number,
    wordProgressScore: Number
  }
};
</script>

<style scoped>
.progressbar-container {
  display: flex;
  position: fixed;
  top: 25px;
  left: 58px;
  right: 24px;
}
.progresses-block {
  flex-grow: 1;
}
.word-counter {
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  padding-top: 1px;
  margin-left: 12px;
}
.some-words {
  color: #4E88FB;
}
.zero-words {
  color: #999999;
}
.progressbar-wrapper-score {
  margin-top: 8px;
  z-index: 10000;
}

.progressbar {
  height: 8px;
  background: #F5F5F6;
  border-radius: 4px;
  overflow: hidden;
  flex-grow: 1;
  position: relative;
}

.progressbar .progress {
  position: absolute;
  top: 0;
  left: 0;
  background: #38D360;
  height: 8px;
  transition: .6s width;
  z-index: 300;
  border-radius: 4px;
  transform: translateX(0px);
}

.progressbar .progress-streak {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--link);
  height: 8px;
  transition: .6s width;
  z-index: 300;
  border-radius: 4px;
  transform: translateX(0px);
}

.answer-was-right .progressbar .progress {
  top: 0;
  right: 0;
  left: auto;
  transform: translateX(100px);
  transition: .6s width, .6s transform;
}

.word-done .progressbar .progress-streak {
  top: 0;
  right: 0;
  left: auto;
  transform: translateX(100px);
  transition: .6s width, .6s transform;
}

.answer-was-wrong .progressbar .progress {
  background: #FF595F;
  transition: 1.6s width;
}

.progressbar-wrapper {
  position: relative;
  z-index: 300;
  display: flex;
}
</style>
