<template>
  <div>
    <div class="home">
      <div v-if="showLoader">
        <orfus-loader class="loader" />
      </div>
      <!-- Round chart -->
      <div v-if="!showLoader" class="main-statistics">
        <div class="circle-chart circle-chart_huge-circle literacy-statistics">
          <round-chart
            :radius="202"
            :progress="totalPoints/totalWords"
            :line-width="10"
            :start-radius="0.75"
            :end-radius="2.25"
            back-color="#F5F7F7"
            main-color="#4E88FB"
          ></round-chart>
          <div class="literacy-statistics__text-block">
            <div class="literacy-statistics__text-block__percents">
              {{ totalPoints }}
            </div>
            <div class="literacy-statistics__text-block__description-text">
              выучено слов<br />из {{ totalWords }}
            </div>
          </div>
        </div>

        <div class="grow-statistics">
          <ul>
            <li>
              <div class="number">+{{pointsToday}}</div>
              <div class="title">за сегодня</div>
            </li>
            <li>
              <div class="number">+{{pointsWeek}}</div>
              <div class="title">за неделю</div>
            </li>
            <li>
              <div class="number">+{{pointsMonth}}</div>
              <div class="title">за месяц</div>
            </li>
          </ul>
        </div>

        <!-- Streak text -->
        <div class="streak-statistics__text-block">
          <div class="streak-text-statistics">
            <div
              class="streak-text-statistics__days"
              :class="[
                points >= pointsTillStreak
                  ? 'streak-text-statistics__days_on'
                  : 'streak-text-statistics__days_off'
              ]"
            >
              вы занимались {{ comboDays }} {{ worddays }} подряд
            </div>
          </div>
        </div>

        <!-- Streak chart -->
        <div class="streak-statistics">
          <div class="streak-chart">
            <div
              class="streak-chart__day"
              :class="[
                date.count >= pointsTillStreak
                  ? 'streak-chart__day_active'
                  : '',
                date.isFuture ? 'streak-chart__day_empty' : ''
              ]"
              v-for="(date, index) in datesArray"
              :key="index"
            ></div>
          </div>
          <div class="months-list">
            <div
              class="months-list__month"
              v-for="(month, index) in months"
              :key="index"
              :style="{ width: month.lengthOfWeeks + 'px' }"
            >
              {{ month.month }}
            </div>
          </div>
        </div>

      </div>
    </div>

    <!-- Actions -->
    <div class="bottom-btn-wrapper">
      <!-- No sub, 0 word -->
      <router-link
        class="btn btn_secondary btn_repetition"
        to="/sub"
        v-show="repetitionWordsCount == 0 && !hasSubscription"
      >
        <svg class="rules-list__item__icon icon-lock" stroke="currentColor" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="4" y="11.5" width="16" height="8.5" rx="2" stroke-width="1.44"/><path d="M8 9C8 6.79086 9.79086 5 12 5V5C14.2091 5 16 6.79086 16 9V11.5H8V9Z" stroke-width="1.44"/><line x1="12" y1="15" x2="12" y2="16.5" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/></svg>
        <span>Все слова закреплены</span>
      </router-link>
      <!-- Sub, 0 word -->
      <a
        class="btn btn_secondary btn_repetition btn_checked"
        href="javascript:void(0)"
        v-show="repetitionWordsCount == 0 && hasSubscription"
      >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor"><path d="M18.5 8L9.5 17L5.5 13" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/></svg>
        <span>Все слова закреплены</span>
      </a>
      <!-- No sub, some word -->
      <router-link
        class="btn btn_secondary btn_repetition"
        to="/sub"
        v-show="repetitionWordsCount > 0 && !hasSubscription"
      >
        <svg class="rules-list__item__icon icon-lock" stroke="currentColor" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="4" y="11.5" width="16" height="8.5" rx="2" stroke-width="1.44"/><path d="M8 9C8 6.79086 9.79086 5 12 5V5C14.2091 5 16 6.79086 16 9V11.5H8V9Z" stroke-width="1.44"/><line x1="12" y1="15" x2="12" y2="16.5" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/></svg>
        <span v-if="repetitionWordsCount != 0">Закрепить {{ repetitionWordsCount }} {{ wordSpelling(repetitionWordsCount) }}</span>
      </router-link>
      <!-- Sub, some word -->
      <router-link
        class="btn btn_secondary btn_repetition btn_linear_wrong"
        to="/repetition"
        v-show="repetitionWordsCount > 0 && hasSubscription"
      >
        <span v-if="repetitionWordsCount != 0">Закрепить {{ repetitionWordsCount }} {{ wordSpelling(repetitionWordsCount) }}</span>
      </router-link>
      <router-link class="btn btn_primary" to="/test">Тренироваться</router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import RoundChart from "./RoundChart.vue";
import OrfusLoader from "./OrfusLoader.vue";

export default {
  components: { RoundChart, OrfusLoader },
  data() {
    return {
      points: 0,
      pointsToday: 0,
      pointsWeek: 0,
      pointsMonth: 0,
      totalPoints: 0,
      datesArray: [],
      pointsTillStreak: 1,
      months: [],
      numberToMonth: [
        "none",
        "янв",
        "фев",
        "мар",
        "апр",
        "май",
        "июн",
        "июл",
        "авг",
        "сен",
        "окт",
        "ноя",
        "дек"
      ]
    };
  },
  mounted() {
    // document.getElementsByTagName("body")[0].style.height = "100vh";
    // document.getElementsByTagName("body")[0].style.overflow = "hidden";
  },
  async mounted() {
    if (this.token) {
      await this.initiatePage();
    }
  },
  watch: {
    token(newValue, oldValue) {
      if (!oldValue && newValue) {
        this.initiatePage();
      }
    }
  },
  computed: {
    ...mapState("user", [
      "token",
      "user",
      "lastAnswer",
      "userPoints",
      "lastAnswerLoading",
      "userPointsLoading",
      "hasSubscription"
    ]),
    ...mapState("test", ["repetitionWordsCount"]),
    ...mapGetters("user", ["userProgress"]),
    showLoader() {
      return !(this.token && this.user && this.userPoints && this.lastAnswer);
    },
    streakcolor() {
      return this.points > 9 ? "#FF8A00" : "#E6E6EF";
    },
    streakprogress() {
      let output = this.points / 10;
      if (output > 1) {
        output = 1;
      }
      return output;
    },
    worddays() {
      if (this.comboDays >= 5 && this.comboDays <= 20) {
        return "дней";
      } else if (this.comboDays % 10 == 1) {
        return "день";
      } else if (this.comboDays % 10 >= 2 && this.comboDays % 10 <= 4) {
        return "дня";
      }
      return "дней";
    },
    comboDays() {
      let result = 0;
      let continueFlag = true;
      for (let i = 0; i < this.datesArray.length; i++) {
        if (this.datesArray[i].count < this.pointsTillStreak && i == 0) {
          // nothing
        } else {
          if (!this.datesArray[i].isFuture) {
            if (
              continueFlag &&
              this.datesArray[i].count >= this.pointsTillStreak
            ) {
              result += 1;
            } else {
              continueFlag = false;
            }
          }
        }
      }
      return result;
    },
    totalWords() {
      if (!this.hasSubscription) {
        return 1000;
      } else {
        return 4000;
      }
    }
  },
  methods: {
    ...mapActions({
      getLastAnswer: "user/getLastAnswer",
      getPoints: "user/getPoints",
      getRepetitionWordsCount: "test/getRepetitionWordsCount"
    }),
    async initiatePage() {
      if (this.userPoints && this.lastAnswer) {
        await this.doCalculations();
      }
      await this.getLastAnswer();
      await this.getPoints();
      await this.doCalculations();
      
    },
    async doCalculations() {
      // reset all local state
      this.points = 0;
      this.pointsToday = 0;
      this.pointsWeek = 0;
      this.pointsMonth = 0;
      this.totalPoints = 0;
      this.datesArray = [];
      this.pointsTillStreak = 1;
      this.months = [];

      this.prepareStreak();

      let isPointsTodayFilled = false;
      this.pointsWeek = 0;
      for (let i = 0; i < 7; i++) {
        if (!isPointsTodayFilled && !this.datesArray[i].isFuture) {
          this.pointsToday = this.datesArray[i].count;
          isPointsTodayFilled = true;
        }
        this.pointsWeek += this.datesArray[i].count;
      }
      this.pointsMonth = 0;
      for (let i = 0; i < 30; i++) {
        this.pointsMonth += this.datesArray[i].count;
      }
      // console.log(this.datesArray[0]);
      // console.log(this.datesArray[1]);
      await this.getRepetitionWordsCount();
    },
    prepareStreak() {
      this.calculateDatesArray();
      this.calculateMonths();
    },
    wordSpelling(num) {
      if (num >= 5 && num <= 20) {
        return "слов";
      } else if (num % 10 == 1) {
        return "слово";
      } else if (num % 10 >= 2 && num % 10 <= 4) {
        return "слова";
      }
      return "слов";
    },
    calculateDatesArray() {
      let today = new Date().toISOString().slice(0, 10);
      let daysWithProgress = this.userPoints;
      for (let i = 0; i < this.userPoints.length; i++) {
        this.totalPoints += this.userPoints[i].count;
      }
      let tempCount;
      let d = new Date();
      let tempDate = new Date();
      let weekDay = d.getDay();
      if (weekDay == 0) {
        weekDay = 7;
      }
      let emptyDays = 7 - weekDay;
      for (let i = emptyDays - 1; i >= 0; i--) {
        tempDate.setDate(d.getDate() + i + 1);
        this.datesArray.push({
          count: 0,
          date: tempDate.toISOString().slice(0, 10),
          isFuture: true
        });
      }
      for (let i = 0; i < 365; i++) {
        tempDate = d.toISOString().slice(0, 10);
        tempCount = 0;
        for (let j = 0; j < daysWithProgress.length; j++) {
          if (daysWithProgress[j].date == tempDate) {
            tempCount = daysWithProgress[j].count;
          }
          if (daysWithProgress[j].date == today) {
            this.points = daysWithProgress[j].count;
          }
        }
        this.datesArray.push({
          count: tempCount,
          date: tempDate,
          isFuture: false
        });
        d.setDate(d.getDate() - 1);
      }
    },
    calculateMonths() {
      let monthOfDay = [];
      for (let i = 0; i < this.datesArray.length; i++) {
        monthOfDay.push(this.datesArray[i].date.substring(5, 7));
      }
      let monthOfWeek = [];
      let tempFirstMonth;
      let tempFirstCount;
      for (let i = 0; i < 30; i++) {
        for (let j = 0; j < 7; j++) {
          if (j == 0) {
            tempFirstMonth = monthOfDay.shift();
            tempFirstCount = 1;
          } else if (tempFirstMonth == monthOfDay.shift()) {
            tempFirstCount += 1;
          }
        }
        if (tempFirstCount > 3) {
          monthOfWeek.push(parseInt(tempFirstMonth));
        } else {
          monthOfWeek.push(parseInt(tempFirstMonth) - 1);
        }
      }
      let monthTextArray = [];
      // 6 months
      for (let i = 0; i < monthOfWeek.length; i++) {
        monthTextArray[monthOfWeek[i]] = 0;
      }
      for (let i = 0; i < monthOfWeek.length; i++) {
        monthTextArray[monthOfWeek[i]] += 1;
      }
      let monthsObjects = [];
      for (let i = 0; i < 6; i++) {
        monthTextArray[monthOfWeek[0] - i];
        monthsObjects.push({
          month: monthOfWeek[0] - i,
          lengthOfWeeks: monthTextArray[monthOfWeek[0] - i]
        });
      }
      let sizeOfSquare = 17;
      for (let i = 0; i < monthsObjects.length; i++) {
        if (monthsObjects[i].lengthOfWeeks > 1) {
          monthsObjects[i].month = this.numberToMonth[monthsObjects[i].month];
        } else {
          monthsObjects[i].month = "";
        }
        monthsObjects[i].lengthOfWeeks =
          monthsObjects[i].lengthOfWeeks * sizeOfSquare;
      }
      // console.log(monthsObjects);
      this.months = monthsObjects;
    }
  }
};
</script>

<style lang="scss">

.btn_checked {
  background: #F5F7F7;
  border-color: #F5F7F7 !important;
  color: #41B860 !important;
}

.btn_linear_wrong {
  border-color: #4E88FB !important;
  color: #4E88FB !important;
}

.grow-statistics ul {
  display: flex;
  padding: 0;
  margin: 0;
}

.grow-statistics li {
  display: block;
  text-align: center;
  margin: 0 16px;
  width: 80px;
}

.grow-statistics .number {
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.grow-statistics .title {
  font-size: 15px;
  line-height: 18px;
  color: #999999;
}

/* MAIN STATISTICS */

.main-statistics {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* CIRCLE CHART */

.circle-chart {
    position: relative;
}

.circle-chart_huge-circle {
    width: 212px;
    height: 212px;
}

.literacy-statistics {
    overflow: hidden;
    margin-top: 24px;
}

.literacy-statistics canvas {
    position: absolute;
    left: 0;
    top: 0;
}

.literacy-statistics__text-block {
    margin-top: 64px;
}

.literacy-statistics__text-block__percents {
    font-weight: bold;
    font-size: 50px;
    line-height: 50px;
    text-align: center;
}

.literacy-statistics__text-block__description-text {
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 7px;
}

/* STREAK STATISTICS */

.streak-statistics {
  align-self: flex-end;
  padding-right: 16px;
}

.streak-chart {
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: column-reverse;
    height: 123px;
    justify-content: flex-end;
}

.streak-chart__day {
    width: 13px;
    height: 13px;
    background: #EFF1F1;
    margin: 2px;
    border-radius: 2px;
}

.streak-chart__day_active {
    background: #EB5358;
}

.streak-chart__day_empty {
    background: rgba(0, 0, 0, 0);
}

.streak-statistics__text-block {
}

.streak-text-statistics__days {
    font-size: 15px;
    line-height: 18px;
    color: #999;
    margin-top: 32px;
    margin-bottom: 8px;
}

.streak-text-statistics__days:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 24px;
    height: 24px;
    content: "";
    display: block;
}

.streak-text-statistics__description {
    font-size: 15px;
    line-height: 15px;
    color: #999999;
}

.months-list {
    display: flex;
    color: #999;
    font-size: 15px;
    line-height: 15px;
    flex-direction: row-reverse;
}

.months-list__month {
    text-align: center;
}

/* BOTTOM BUTTONS */

.bottom-btn-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    background: #fff;
}

.btn_repetition {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.btn_repetition svg {
  margin-right: 8px;
}
</style>
