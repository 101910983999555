<template>
  <div class="main-menu">
    <router-link to="/" class="main-menu__item">
      <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 20V14.5M5.5 14.5V5.5C5.5 5.5 6 4.50008 8.5 4.5C11 4.49992 13 6.5 15.5 6.5C18 6.5 18.5 5.5 18.5 5.5V14.5C18.5 14.5 18 15.5 15.5 15.5C13 15.5 11 13.5 8.5 13.5C6 13.5 5.5 14.5 5.5 14.5Z" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/></svg>
      <span class="main-menu__item__text">Тренировка</span>
    </router-link>
    <router-link to="/rules" class="main-menu__item">
      <svg width="24" height="24" stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 7.00015V19.0002M12 7.00015C12 7.00015 14 5.18941 16 5.00015C18 4.8109 20 6.00015 20 6.00015V18.0002C20 18.0002 18 16.811 16 17.0002C14 17.1893 12 19.0002 12 19.0002M12 7.00015C12 7.00015 10 5.18934 8 5.00015C6 4.81096 4 6.00015 4 6.00015V18.0002C4 18.0002 6 16.811 8 17.0002C10 17.1893 12 19.0002 12 19.0002" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/></svg>
      <span class="main-menu__item__text">Правила</span>
    </router-link>
    <router-link to="/profile" class="main-menu__item">
      <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="7.75" r="3.25" stroke-width="1.44"/><path d="M5 19.5V17C5 15.3431 6.34315 14 8 14H16C17.6569 14 19 15.3431 19 17V19.5" stroke-width="1.44" stroke-linecap="round" stroke-linejoin="round"/></svg>
      <span class="main-menu__item__text">Аккаунт</span>
    </router-link>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .main-menu {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 53px;
    display: flex;
    box-sizing: border-box;
    justify-content: space-around;
    border-top: 1px solid rgba(0, 0, 0, .1);
    color: #000;

    .main-menu__item {
      width: 33.333%;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 6px;
      color: rgba(0, 0, 0, .4);


      .main-menu__item__icon {
        
      }

      .main-menu__item__text {
        display: block;
        margin-top: 3px;
        font-size: 11px;
        line-height: 11px;
      }

      &.router-link-exact-active {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
</style>