import api from "../../services/api";
import { NETWORK_ERROR } from "../../services/api";

const ADD_WORDS_TO_TESTS = "ADD_WORDS_TO_TESTS";
const SET_LOADING = "SET_LOADING";
const SET_NO_INTERNET = "SET_NO_INTERNET";
const SET_CURRENT_RULE = "SET_CURRENT_RULE";
const SET_RULES = "SET_RULES";
const SET_REPETITION_WORDS_COUNT = "SET_REPETITION_WORDS_COUNT";
const SET_REPETITION_WORDS = "SET_REPETITION_WORDS";
const ACTIVE_TEST_INDEX = "ACTIVE_TEST_INDEX";

const handleNetworkError = async (callback, commit) => {
  const result = await callback();
  if (result.error) {
    if (result.type === NETWORK_ERROR) {
      commit(SET_NO_INTERNET, true);
    }
    return false;
  } else {
    commit(SET_NO_INTERNET, false);
    return result;
  }
};

export default {
  namespaced: true,
  state: () => ({
    tests: [],
    currentRule: null,
    loading: false,
    noInternet: false,
    rules: [],
    repetitionWordsCount: 0,
    repetitionWords: [],
    activeTestIndex: 0
  }),
  mutations: {},
  actions: {
    getNewWord: async ({ commit }, wordsNumber = 1) => {
      commit(SET_LOADING, true);
      const response = await handleNetworkError(
        () => api.get(`/words/next?size=${wordsNumber}`),
        commit
      );
      if (response) {
        let processedWords = response.map(word => ({
          word: word.word,
          lifehack: word.lifehack,
          wordStart: word.word.split("..")[0],
          wordEnd: word.word.split("..")[1],
          options: [
            {
              text: word.right,
              isRight: true,
              answerState: ""
            },
            {
              text: word.wrong,
              isRight: false,
              answerState: ""
            }
          ].sort(() => Math.random() - 0.5)
        }));
        commit(ADD_WORDS_TO_TESTS, processedWords);
      }
      commit(SET_LOADING, false);
    },
    sendAnswer: async (context, answer) => {
      return await api.post("/words/check", answer);
    },
    getRule: async ({ commit }, ruleName) => {
      const rule = await api.get(`/rules/${ruleName}`);
      commit(SET_CURRENT_RULE, rule);
    },
    getRules: async ({ commit }) => {
      const rules = await api.get(`/rules`);
      commit(SET_RULES, rules);
    },
    getRepetitionWordsCount: async ({ commit }) => {
      const result = await api.get(`/words/repetitions/info`);
      commit(SET_REPETITION_WORDS_COUNT, result.words || 0);
    },
    getRepetitionWords: async ({ commit }) => {
      const words = await api.get(`/words/repetitions`);
      if (words) {
        let processedWords = words.map(word => ({
          word: word.word,
          lifehack: word.lifehack,
          wordStart: word.word.split("..")[0],
          wordEnd: word.word.split("..")[1],
          options: [
            {
              text: word.right,
              isRight: true,
              answerState: ""
            },
            {
              text: word.wrong,
              isRight: false,
              answerState: ""
            }
          ].sort(() => Math.random() - 0.5)
        }));
        commit(SET_REPETITION_WORDS, processedWords);
      }
    },
    deleteRepetitionWord: async ({ commit }, word) => {
      console.log(word);
      await api.delete(`/words/repetitions`, word);
    },
    increseActiveTestIndex: async ({ commit }, activeTestIndex) => {
      commit(ACTIVE_TEST_INDEX, activeTestIndex);
    }
  },
  mutations: {
    [ADD_WORDS_TO_TESTS](state, newWords) {
      state.tests = [...state.tests, ...newWords];
    },
    [SET_CURRENT_RULE](state, rule) {
      state.currentRule = rule;
    },
    [SET_LOADING](state, status) {
      state.loading = status;
    },
    [SET_NO_INTERNET](state, status) {
      state.noInternet = status;
    },
    [SET_RULES](state, rules) {
      state.rules = rules;
    },
    [SET_REPETITION_WORDS_COUNT](state, count) {
      state.repetitionWordsCount = count;
    },
    [SET_REPETITION_WORDS](state, words) {
      state.repetitionWords = words;
    },
    [ACTIVE_TEST_INDEX](state, index) {
      state.activeTestIndex = index;
    }
  }
};
