import VueRouter from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import TestPage from "./components/TestPage.vue";
import Rule from "./components/Rule.vue";
import RulesPage from "./components/RulesPage.vue";
import ProfilePage from "./components/ProfilePage.vue";
import RepetitionPage from "./components/RepetitionPage.vue";
import SubPage from "./components/SubPage.vue";

const routes = [
  { path: "/", name: "home", component: Home },
  { path: "/login", name: "login", component: Login },
  { path: "/rules", name: "rules", component: RulesPage },
  { path: "/rules/:id", name: "specificRule", component: Rule },
  { path: "/profile", name: "profile", component: ProfilePage },
  {
    path: "/sub",
    name: "sub",
    component: SubPage,
    meta: { hideMenu: true }
  },
  {
    path: "/test",
    name: "test",
    component: TestPage,
    meta: { hideMenu: true },
    children: [
      {
        path: "rule",
        name: "rule",
        component: Rule,
        meta: { hideMenu: true }
      }
    ]
  },
  {
    path: "/repetition",
    name: "repetition",
    component: RepetitionPage,
    meta: { hideMenu: true },
    children: [
      {
        path: "rule",
        name: "rule",
        component: Rule,
        meta: { hideMenu: true }
      }
    ]
  }
];

export default new VueRouter({
  mode: "history",
  routes
});
